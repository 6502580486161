import { request } from "@/utils/request.js";
import axios from "axios";
import { SHIP_SEARCH_URL, GPS_SEARCH } from "./enum";

export function getShip(param) {
    return request("ships/getMmsi", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * @description 设置换船标识
 * @param {Number} id
 * @param {Boolean} flag
 * @returns
 */
export function setShipChangeFlag(param){
    return request("/blno/userBlno/setVessels", "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * @description 异常反馈
 * @param {String} blno 提单号
 * @param {String} blnoId 提单id
 * @param {String} problem 反馈内容
 * @param {String} code 类型
 * @returns
 */
export function efforFeedback(param){
    return request("/blno/userBlno/addFeedback", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }, true);
}

/**
 * @description 异常反馈结果
 * @param {String} blnoId 提单号id
 * @returns
 */
export function efforFeedbackResult(param){
    return request("/blno/userBlno/getFeedback", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    },true);
}

// 分页查船期
export function queryShipData(param) {
    return request("/vessels/userBlno/selectPage", "post", param);
}
// 上海分页查船期
export function queryShVessels(param) {
    return request("/vessels/shVessels/selectPage", "post", param);
}

// 上海分页查船期
export function getVesselByKeyword(param) {
    return request("/vessels/shVessels/searchByKeywords", "get", param);
}

/**
 * 获取mmsi
 * @param { string } type
 * @param { string } vesselsName
 */
export function shipSearch(param) {
    return request(
        "https://prod-api.fangcangwang.com/blno/port/getMmsi",
        "get",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

export function getShipTrack(param) {
    return request("/api/gateway/GetShipTrack_ais", "get", param, {
        baseURL: GPS_SEARCH,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "API-TOKEN": "C12960F0ADD358BDD8293A038A83319F",
        },
    });
}

export function getGPS(param) {
    return request("/api/gateway/dm_sjtt_GPS_SEARCH", "get", param, {
        baseURL: GPS_SEARCH,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "API-TOKEN": "A0076E9C199204116DA46D41F62F4B7C",
        },
    });
}

/**
 * 获取船队轨迹
 * @param {string} mmsi
 * @param {string} start
 * @param {string} end
 */
export function getTrack(param) {
    return request(
        "https://prod-api.fangcangwang.com/blno/port/getAisTrack",
        "get",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

/**
 * 查询船舶信息
 * @param {string} mmsi
 * @param {string} type
 */
export function getAisInfo(param) {
    return request(
        "https://prod-api.fangcangwang.com/blno/port/getAisInfo",
        "get",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

/**
 * 批量查询船舶信息
 * @param {Array} aisInfoDtos // 船舶mmsi列表
 */
export function batchGetAisInfo(param) {
    return request("/blno/port/getAisInfos", "post", param);
}

export function getWxShip(param) {
    return request("/blno/port/searchShip", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 获取船名航次用于搜索
export function searchVeVo(param) {
    return request(`blno/userBlno/queryVeVo`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 获取船名航次用于搜索上海
export function shVesselsSearchVeVo(param) {
    return request(`vessels/shVessels/queryVeVo`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 置顶
export function toTop(ids) {
    return request("vessels/toppingList", "post", ids);
}

// 上海船期置顶
export function toTopSH(ids) {
    return request("vessels/shVessels/toppingList", "post", ids);
}

// 上海船期取消置顶
export function calToTopSH(ids) {
    return request("vessels/shVessels/unToppingList", "post", ids);
}

// /api/gateway/dm_sjtt_GPS_SEARCH
// 模板下载
export function blnoExcel(params) {
    return request(`blno/downLoad/excel/`, "get", params, "blob", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 文件上传
/**
 *
 * @param {file} file
 * @param {string} code
 * @returns
 */
export function Excel(params) {
    return request("vessels/userBlno/uploadVesselsExcel", "post", params);
}



/**
 * 宁波修改码头
 * @param {number} id
 * @param {string} wharfCode
 */

export function updateWharf(param){
    return request("/vessels/userBlno/updateWharf", "get", param);
}
